<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>教研中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/evaluation' }">测评</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <el-form ref="form" v-loading="boxLoading" :model="form" :rules="rules" label-width="120px" size="mini">
                <el-form-item label="名称：" label-position="left" class="input" prop="name">
                    <el-input style="width: 200px" v-model="form.name"></el-input>
                </el-form-item>



                <el-form-item>
                    <el-button size="small" type="primary" :loading="loading" @click="checkOnSubmit">保存</el-button>
                    <router-link to="/evaluation" class="router-link"><el-button style="margin-left: 10px"
                            size="small">取消</el-button></router-link>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: 'Add',
    data() {
        return {
            form: {},
            rules: {
                name: [{ required: true, message: '请输入名称', trigger: 'blur' },]
            },
            loading: false,
            boxLoading:false
        }
    },
    methods: {
        ...mapActions('evaluation', ['addEvaluation', 'getEvaluation']),
        checkOnSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.add()
                }
            });
        },
        async add() {
            this.loading = true
            const { res_info } = await this.addEvaluation(this.form)
            this.loading = false
            if (res_info != 'ok') return
            this.$message.success('编辑成功！')
            this.$router.push('/evaluation')
        },
        async init() {
            this.boxLoading = true
            const { data } = await this.getEvaluation({ id: this.$route.params.id })
            this.form = data
            this.boxLoading = false
        },
    },
    mounted() {
        this.init()
    },
}
</script>


